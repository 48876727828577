
import { computed, ref } from "vue";
import { unescape as _unescape, isObject as _isObject } from "lodash";
import { renderData, formatName } from "@libraryHelpers/dataComponents.js";
export default {
	name: "CoreBlockFooterLogo",
	props: {
		site: {
			type: Object,
			required: true,
		},
		isFooterMobile: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const footer = computed(() => props.site.footer);
		const isNYL = computed(() => props.site?.brandId === 3);
		const isGO = computed(() => props.site.tags?.includes(1305) ?? false);

		const defaultLogo = ref({
			src: "/img/logos/nyl-logo-blue.svg",
			alt: "New York Life Insurance",
		});

		const logo = computed(() => {
			const logoSrc = _isObject(footer.value.logo)
				? footer.value.logo.src
				: footer.value.logo;
			return (
				renderData(logoSrc, props.site) ||
				(isNYL.value ? defaultLogo.value.src : "/img/core-logo.svg")
			);
		});

		const logoShow = computed(() => {
			if (
				Object.prototype.hasOwnProperty.call(footer.value, "logoShow")
			) {
				return footer.value.logoShow;
			}
			return null;
		});
		const logoStyle = computed(() => footer.value.logoStyle);

		const showText = computed(() => {
			// logoShow is a new field with new logic, so if it does not exist, respect the old logic.
			if (logoShow.value === null) {
				return (
					logoStyle.value !== "large" &&
					logoStyle.value !== "none" &&
					!props.isFooterMobile
				);
			}
			return !props.isFooterMobile;
		});

		const showLogo = computed(() => {
			// logoShow is a new field with new logic, so if it does not exist, respect the old logic.
			return logoShow.value !== null
				? logoShow.value
				: logoStyle.value !== "none";
		});

		const nameFooter = computed(() => {
			const formattedName = formatName(
				footer.value.nameFooter,
				props.site.user?.ddcUserData
			);
			return hasDataNameFooter.value
				? _unescape(renderData(formattedName, props.site))
				: props.site.title;
		});

		const logoAltText = computed(() => {
			const logoAlt = _isObject(footer.value.logo)
				? footer.value.logo.alt
				: footer.value.logoAltText;

			const defaultAlt =
				logo.value === defaultLogo.value.src && defaultLogo.value.alt;
			return logoAlt || defaultAlt || nameFooter.value;
		});

		const logoLink = computed(() => footer.value.logoLink || "/#");

		const logoInternalLink = computed(() => logoLink.value.startsWith("/"));

		const logoTag = computed(() => {
			if (logoInternalLink.value) {
				return "NuxtLink";
			}
			return "a";
		});

		const externalLink = computed(() => logoLink.value);

		const goHeadquarters = computed(
			() => props.site.user?.ddcUserData?.orgUnitDesc
		);

		const overlineFooter = computed(() => {
			if (isGO.value) {
				return goHeadquarters.value + " General Office";
			}
			return _unescape(
				renderData(footer.value.overlineFooter, props.site)
			);
		});

		const isManagingPartner = computed(() => {
			if (
				props.site.user?.ddcUserData?.marketerTitleTpDesc?.toLowerCase() ===
				"managing partner"
			) {
				return true;
			}

			return false;
		});

		const jobTitleFooter = computed(() => {
			if (isManagingPartner.value) {
				return "Managing Partner";
			}
			return renderData(footer.value.jobTitleFooter, props.site);
		});

		const showUsernameFooter = computed(
			() => footer.value.showUsernameFooter
		);

		const showOverlineFooter = computed(
			() => footer.value.showOverlineFooter
		);

		const showTitleFooter = computed(() => footer.value.showTitleFooter);

		const hasDataNameFooter = computed(() => {
			const dataName =
				renderData(footer.value.nameFooter, props.site) || "";
			return !!dataName.trim(); // the default mainnav name has spaces between vars, so it will end up something like "  " even if there are no values
		});

		const displayName = computed(
			() => props.site.user?.ddcUserData?.displayName
		);

		const showForm = computed(() => {
			const forms = ["left-aligned-form", "center-aligned-form"];
			return isNYL.value && forms.includes(footer.value.component);
		});

		const showImage = computed(() => {
			const imageTypes = [
				"large-image-three-contact",
				"small-image-three-contact",
			];
			return imageTypes.includes(footer.value.component);
		});

		const isCenterForm = computed(
			() => footer.value.component === "center-aligned-form"
		);

		const isLargeImage = computed(
			() => footer.value.component === "large-image-three-contact"
		);

		const isCenterLogo = computed(() => {
			const contactTypes = [
				"center-logo-two-contact",
				"center-logo-stack-contact",
			];
			return contactTypes.includes(footer.value.component);
		});

		const logoClass = computed(() => {
			if (isCenterLogo.value) {
				return "logo-center";
			}
			if (showForm.value || showImage.value) {
				return isCenterForm.value || isLargeImage.value
					? "logo-left"
					: "logo-right";
			}
			return "";
		});

		const logoImgClass = computed(
			() => `logo_image logo_image--${logoStyle.value}`
		);

		return {
			overlineFooter,
			showOverlineFooter,
			hasDataNameFooter,
			showUsernameFooter,
			displayName,
			nameFooter,
			jobTitleFooter,
			showTitleFooter,
			logoStyle,
			logoTag,
			logoInternalLink,
			logoLink,
			externalLink,
			logoAltText,
			logoClass,
			logoImgClass,
			logo,
			showText,
			showLogo,
		};
	},
};
