
import { renderData } from "@libraryHelpers/dataComponents.js";
import { computed } from "vue";

export default {
	props: {
		footer: {
			type: Object,
			required: true,
		},
		clonedSite: {
			type: Object,
			required: true,
		},
		isFooterMobile: {
			type: Boolean,
			required: true,
		},
		align: {
			type: String,
			default: "left",
			validator: (value) => ["left", "center", "right"].includes(value),
		},
	},
	setup(props) {
		const showSocialTitle = computed(() => {
			// social-title-show does not exist on older sites
			return Object.prototype.hasOwnProperty.call(
				props.footer,
				"social-title-show"
			)
				? props.footer["social-title-show"]
				: true;
		});

		const facebook = computed(() => {
			if (!props.footer?.["facebook-show"]) return "";
			return renderData(props.footer.facebook, props.clonedSite);
		});
		const linkedin = computed(() => {
			if (!props.footer?.["linkedin-show"]) return "";
			return renderData(props.footer.linkedin, props.clonedSite);
		});
		const twitter = computed(() => {
			if (!props.footer?.["twitter-show"]) return "";
			return renderData(props.footer.twitter, props.clonedSite);
		});
		const hasSocialLink = computed(() => {
			return facebook.value || linkedin.value || twitter.value;
		});

		return {
			showSocialTitle,
			facebook,
			linkedin,
			twitter,
			hasSocialLink,
		};
	},
};
