import { render, staticRenderFns } from "./CoreBlockFooterContactGroup.vue?vue&type=template&id=7ae6639d&scoped=true"
import script from "./CoreBlockFooterContactGroup.vue?vue&type=script&lang=js"
export * from "./CoreBlockFooterContactGroup.vue?vue&type=script&lang=js"
import style0 from "./CoreBlockFooterContactGroup.vue?vue&type=style&index=0&id=7ae6639d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ae6639d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlockFooterLogo: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterLogo.vue').default,CoreBlockFooterSocialLinks: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterSocialLinks.vue').default,CoreBlockFooterContact: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/contact/CoreBlockFooterContact.vue').default})
