
import { computed } from "vue";
import CoreBlockFooterContactAddress from "./CoreBlockFooterContactAddress.vue";
import CoreBlockFooterContactPhone from "./CoreBlockFooterContactPhone";
import CoreBlockFooterContactEmail from "./CoreBlockFooterContactEmail";

export default {
	name: "CoreBlockFooterContact",
	components: {
		CoreBlockFooterContactAddress,
		CoreBlockFooterContactPhone,
		CoreBlockFooterContactEmail,
	},
	props: {
		contact: {
			type: Object,
			required: true,
		},
		site: {
			type: Object,
			required: true,
		},
		type: {
			type: String,
			default: null,
			validator: (type) => ["contacts", "locations"].includes(type),
		},
		teamMemberId: {
			type: String,
			default: null,
		},
		isStacked: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const flattenedContact = computed(() => {
			const { type1, type2, type3 } = props.contact;
			return { ...type1, ...type2, ...type3 };
		});

		const footer = computed(() => {
			return props.site.footer;
		});

		const teamMember = (teamMemberKey) => {
			const memberId = footer.value[teamMemberKey || props.teamMemberId];
			return props.site?.teamMembers?.find(
				(member) => memberId !== -1 && member.id === memberId
			);
		};

		const displayHeading = computed(() =>
			props.isStacked ? true : props.contact.displayTitle
		);
		const heading = computed(() => {
			if (props.isStacked) {
				if (props.type === "contacts") {
					return "Contact us";
				}
				if (props.type === "locations") {
					return "Our Locations";
				}
			}
			return props.contact.title;
		});
		const officePhone = computed(() => {
			if (teamMember(props.teamMemberId)) {
				return {
					label: flattenedContact.value.officePhone?.label,
					phone: teamMember(props.teamMemberId).phoneNumber || "",
				};
			}
			return flattenedContact.value.officePhone;
		});
		const altPhone = computed(() => flattenedContact.value.altPhone);
		const mobile = computed(() => flattenedContact.value.mobile);
		const fax = computed(() => flattenedContact.value.fax);

		const email = computed(() => {
			if (teamMember(props.teamMemberId)) {
				return teamMember(props.teamMemberId)?.emailAddress;
			}
			return flattenedContact.value.email;
		});

		const address = computed(() => {
			if (teamMember(props.teamMemberId)) {
				const member = teamMember(props.teamMemberId);
				return {
					label: flattenedContact.value.address?.label,
					streetAddress: member.address,
					city: member.city,
					state: member.state,
					zip: member.postalCode,
				};
			}
			return flattenedContact.value.address;
		});

		/**
		 * @returns {Object} An object containing addresses and contacts data.
		 * @description Merges properties from `type1`, `type2`, and `type3` for each contact location.
		 * Iterates through contact locations and merges properties from the specified types.
		 */
		const contactData = computed(() => {
			if (!props.isStacked) return { addresses: {}, contacts: {} };
			const types = ["type1", "type2", "type3"];
			const locations = [
				footer.value["contact-1"],
				footer.value["contact-2"],
				footer.value["contact-3"],
			];
			const addressObjects = {};
			const contactObjects = {};

			locations.forEach((location, index) => {
				const contactShow = Object.hasOwnProperty.call(
					footer.value,
					`contact-${index + 1}-show`
				)
					? footer.value[`contact-${index + 1}-show`]
					: true;
				if (location && contactShow) {
					const contactKey = `contact-${index + 1}`;
					const teamMemberKey = `team-member-${index + 1}`;
					const mergedProps = {};

					types.forEach((type) => {
						if (location[type]) {
							Object.assign(mergedProps, location[type]);
						}
					});

					const { address, ...contactProps } = mergedProps;
					if (address) {
						if (teamMember(teamMemberKey)) {
							const member = teamMember(teamMemberKey);
							addressObjects[contactKey] = {
								label: address.label,
								streetAddress: member.address,
								city: member.city,
								state: member.state,
								zip: member.postalCode,
							};
						} else {
							addressObjects[contactKey] = address;
						}
					}
					if (Object.keys(contactProps).length > 0) {
						if (teamMember(teamMemberKey)) {
							const member = teamMember(teamMemberKey);
							contactObjects[contactKey] = {
								...contactProps,
								officePhone: {
									label: contactProps.officePhone?.label,
									phone: member.phoneNumber,
								},
								email: member.emailAddress,
							};
						} else {
							contactObjects[contactKey] = contactProps;
						}
					}
				}
			});

			return { addresses: addressObjects, contacts: contactObjects };
		});

		return {
			heading,
			displayHeading,
			officePhone,
			altPhone,
			mobile,
			fax,
			email,
			address,
			contactData,
			teamMember,
		};
	},
};
