
import { computed } from "vue";
import { renderData } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlockFooterContactAddress",
	props: {
		address: {
			type: Object,
			required: true,
		},
		site: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const streetAddress = computed(() => {
			return renderData(props.address.streetAddress || "", props.site);
		});
		const suite = computed(() =>
			renderData(props.address.suiteNumber || "", props.site)
		);
		const city = computed(() => {
			return renderData(props.address.city || "", props.site);
		});
		const state = computed(() => {
			return renderData(props.address.state || "", props.site);
		});
		const zip = computed(() => {
			return renderData(props.address.zip || "", props.site);
		});

		const cleanAddress = () => {
			return (
				`http://maps.google.com/?q=` +
				encodeURIComponent(
					`${props.address.streetAddress} ${props.address.city} ${props.address.state} ${props.address.zip}`
				)
			);
		};
		return {
			streetAddress,
			suite,
			city,
			state,
			zip,
			cleanAddress,
		};
	},
};
