import { computed } from "vue";

export function useMedia(mediaSrc) {
	const mediaUrl = computed(() => {
		return mediaSrc.value && typeof mediaSrc.value === "object"
			? mediaSrc.value.src
			: mediaSrc.value;
	});

	const isImage = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(
					/\.jpg|\.jpeg|\.jfif|\.pjpeg|\.pjp|\.gif|\.png|\.svg|\.webp|^data:image\//i
			  )
			: null;
	});

	const isFile = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(/\.csv|\.pdf|\.html|\.txt/i)
			: null;
	});

	const isBlobFile = computed(() => {
		return mediaUrl.value ? mediaUrl.value.includes("uploads") : false;
	});

	const isYoutube = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(/https:\/\/www\.youtube\.com\/embed\/.*/)
			: null;
	});

	const isVimeo = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(/https:\/\/player\.vimeo\.com\/video\/.*/)
			: null;
	});

	const isBrightcove = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(/https:\/\/players\.brightcove\.net\/.*/)
			: null;
	});

	const isEMoney = computed(() => {
		return mediaUrl.value
			? mediaUrl.value.match(/https:\/\/abm\.emaplan\.com\/.*/)
			: null;
	});

	const isUploadedVideo = computed(() => {
		if (mediaUrl.value) {
			return (
				/\.(mp4|mov|avi|webm)(\?.*)?$/i.test(mediaUrl.value) &&
				isBlobFile.value
			);
		}
		return null;
	});

	const isVideo = computed(() => {
		return (
			isYoutube.value ||
			isVimeo.value ||
			isBrightcove.value ||
			isEMoney.value ||
			isUploadedVideo.value
		);
	});

	const videoAutoPlayUrl = computed(() => {
		if (!isVideo.value) return "";

		try {
			const url = new URL(mediaUrl.value);

			if (isYoutube.value) {
				url.searchParams.set("autoplay", "1");
				url.searchParams.set("mute", "1");
				url.searchParams.set("rel", "0");
				url.searchParams.set("iv_load_policy", "3");
				return url.toString();
			}

			url.searchParams.set("autoplay", "1");
			url.searchParams.set("muted", "1");
			return url.toString();
		} catch (error) {
			return mediaUrl.value;
		}
	});

	const videoLoopUrl = computed(() => {
		if (!isVideo.value) return "";

		try {
			/** We use autoplay Url as a base for loop Url */
			const url = new URL(videoAutoPlayUrl.value);

			if (isYoutube.value) {
				const videoId = url.toString().match(/\/embed\/([^?]*)/)[1];
				url.searchParams.set("loop", "1");
				url.searchParams.set("playlist", videoId);
				return url.toString();
			}

			url.searchParams.set("loop", "1");
			return url.toString();
		} catch (error) {
			return videoAutoPlayUrl.value;
		}
	});

	const mediaType = computed(() => {
		if (isImage.value) {
			return "image";
		}
		if (isVideo.value) {
			return "video";
		}
		if (isFile.value) {
			return "file";
		}
		return null;
	});

	return {
		mediaUrl,
		isImage,
		isFile,
		isBlobFile,
		isYoutube,
		isVimeo,
		isBrightcove,
		isEMoney,
		isUploadedVideo,
		isVideo,
		mediaType,
		videoAutoPlayUrl,
		videoLoopUrl,
	};
}
