
import { computed } from "vue";
import CoreBlockFooterContact from "./CoreBlockFooterContact.vue";
import CoreBlockFooterSocialLinks from "../CoreBlockFooterSocialLinks.vue";
import CoreBlockFooterLogo from "../CoreBlockFooterLogo.vue";

export default {
	name: "CoreBlockFooterContactGroup",
	components: {
		CoreBlockFooterSocialLinks,
		CoreBlockFooterContact,
		CoreBlockFooterLogo,
	},
	props: {
		site: {
			type: Object,
			required: true,
		},
		isFooterMobile: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const footer = computed(() => {
			return props.site.footer;
		});

		const isStackedContentType = computed(() => {
			const contactTypes = [
				"center-logo-stack-contact",
				"left-logo-stack-contact",
			];
			return contactTypes.includes(footer.value.component);
		});

		const isCenterLogoType = computed(() => {
			const contactTypes = [
				"center-logo-two-contact",
				"center-logo-stack-contact",
			];
			return contactTypes.includes(footer.value.component);
		});

		const contact1 = computed(() => {
			return footer.value["contact-1"];
		});

		const showContact1 = computed(() => {
			if (
				Object.prototype.hasOwnProperty.call(
					footer.value,
					"contact-1-show"
				) &&
				!isStackedContentType.value
			) {
				return footer.value["contact-1-show"];
			}
			return true;
		});

		const contact2 = computed(() => {
			return footer.value["contact-2"];
		});

		const showContact2 = computed(() => {
			if (
				Object.prototype.hasOwnProperty.call(
					footer.value,
					"contact-2-show"
				) &&
				!isStackedContentType.value
			) {
				return footer.value["contact-2-show"];
			}
			return true;
		});

		const contact3 = computed(() => {
			return footer.value["contact-3"];
		});

		const showContact3 = computed(() => {
			if (isCenterLogoType.value || isStackedContentType.value) {
				return false;
			}
			if (
				Object.prototype.hasOwnProperty.call(
					footer.value,
					"contact-3-show"
				)
			) {
				return footer.value["contact-3-show"];
			}
			return true;
		});

		return {
			contact1,
			showContact1,
			contact2,
			showContact2,
			contact3,
			showContact3,
			isCenterLogoType,
			isStackedContentType,
			footer,
		};
	},
};
