
import { computed } from "vue";
import { renderData } from "@libraryHelpers/dataComponents.js";
import { formatPhoneNumber } from "@libraryHelpers/regex";

export default {
	name: "CoreBlockFooterContactPhone",
	props: {
		phone: {
			type: Object,
			required: true,
		},
		site: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const phoneNumber = computed(() =>
			formatPhoneNumber(renderData(props.phone.phone || "", props.site))
		);

		const phoneExt = computed(
			() => renderData(props.phone.extension || "", props.site) || ""
		);

		const hasExtension = computed(() => phoneExt.value !== "");

		const clickToCall = (phoneNumber, extension = "") => {
			const cleanPhoneNumber =
				phoneNumber && phoneNumber.replace(/[^0-9+]/g, "");
			const cleanExtension =
				extension && extension.replace(/[^0-9+]/g, "");
			if (cleanExtension)
				return `tel:${cleanPhoneNumber};${cleanExtension}`;
			return `tel:${cleanPhoneNumber}`;
		};

		return {
			clickToCall,
			phoneNumber,
			phoneExt,
			hasExtension,
		};
	},
};
