
import { computed } from "vue";
import { useLoadComponent } from "@libraryComposables/useLoadComponent";
import { useEmptyComponent } from "@libraryComposables/useEmptyComponent";
import { useStore, useRoute } from "../../helpers/compositionApi";
import { sitePath } from "@libraryHelpers/dataComponents.js";
import { useIsPresetExpired } from "@libraryComposables/useIsExpired";

export default {
	name: "CoreBlock",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		disableLink: {
			type: Boolean,
			default: false,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
		nestedLevel: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const store = useStore();
		const route = useRoute();
		const dataSite = computed(() => sitePath(store));
		const { componentModule } = useLoadComponent(props);
		const { classes: emptyComponentClasses } = useEmptyComponent(
			props?.settings
		);

		const { isExpired } = useIsPresetExpired(props.settings.presetId);

		const internalLink = computed(() => {
			return (
				props?.settings?.linkInternal ||
				props.settings?.linkUrl?.startsWith("/")
			);
		});

		const blockTag = computed(() => {
			if (props.settings?.linkUrl && !props.isEditable) {
				return internalLink.value ? "NuxtLink" : "a";
			}
			return "div";
		});

		const linkUrl = computed(() => {
			if (props.disableLink) {
				return "";
			}
			if (props.settings?.linkUrl?.charAt(0) === "#") {
				return {
					hash: props.settings.linkUrl,
					path: route.path,
				};
			}
			return props.settings?.linkUrl;
		});

		const linkTarget = computed(() => {
			return props.settings.linkInternal ? "_self" : "_blank";
		});

		const blockId = computed(() => props.settings.id);

		const blockStyle = computed(() => {
			const styleSetting = props.settings.style || "";
			return [styleSetting]
				.join("\n")
				.replaceAll("#id", `#${blockId.value}`);
		});

		const blockType = computed(() => props.settings.type);

		const isCompliance = computed(() =>
			route?.name?.includes("compliance")
		);

		const blockVariants = computed(() => {
			const variants = [];
			if (props.settings.variants) {
				for (const [variantKey, variantValue] of Object.entries(
					props.settings.variants
				)) {
					// go through every variant saved that is not of the "_custom" key
					if (variantKey !== "_custom") {
						// variants defined without options are just toggles
						// and if they are true, then apply the variant key
						if (variantValue === true) {
							variants.push(variantKey);
						}
						// variants defined with specific string value options
						// should apply the selected value
						else {
							variants.push(variantValue);
						}
					}
				}
			}
			if (!variants.length) {
				variants.push("base");
			}
			return variants;
		});

		const blockClasses = computed(() => {
			const classList = ["block", `block_${blockType.value}`];
			blockVariants.value.forEach((variant) => {
				if (variant) {
					classList.push(`block_${blockType.value}_${variant}`);
				}
			});
			// anything added to the _custom key is a string w/ space delimited custom classes
			if (props.settings.variants?._custom) {
				classList.push(props.settings.variants._custom.split(" "));
			}
			// Add global animation from site settings
			if (dataSite.value?.animationStyle) {
				classList.push(
					"animate__animated animate__" +
						dataSite.value.animationStyle
				);
			}

			/** Add empty component classes */
			if (!isCompliance.value) {
				classList.push(...emptyComponentClasses.value);
			}

			if (
				props?.settings.type == "repeat" &&
				!props.settings.dictionaryId
			) {
				classList.push(["repeat_without_source"]);
			}

			return classList;
		});

		return {
			dataSite,
			blockTag,
			linkUrl,
			linkTarget,
			internalLink,
			blockId,
			blockStyle,
			blockType,
			blockVariants,
			blockClasses,
			componentModule,
			isExpired,
		};
	},
};
