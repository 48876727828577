
import { computed, ref, watch } from "vue";
import { useMedia } from "@libraryComposables/useMedia";
import mediaHelper from "@libraryHelpers/media.js";

export default {
	name: "CoreBlockFooterBackground",
	props: {
		footer: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const backgroundImage = computed(
			() => props.footer.backgroundImage || {}
		);
		const croppedImage = ref(null);

		const { isImage } = useMedia(backgroundImage);

		watch(backgroundImage, (newValue) => {
			if (!newValue || !newValue.src) {
				croppedImage.value = null;
			}
		});

		const background = computed(() => {
			if (!backgroundImage.value) return "";
			const image =
				croppedImage.value ||
				mediaHelper.getEncodedURI(backgroundImage.value?.src);
			if (!image) return "";

			return {
				"background-image": `url(${image})`,
				"background-position": backgroundImage.value?.focal?.bgPosition,
				"background-repeat": "no-repeat",
				"background-size": "cover",
				height: "inherit",
			};
		});

		const backgroundClass = computed(() => {
			const color = props.footer.backgroundColor;
			switch (color) {
				case "white":
					return "footer__background--white";
				case "grey":
					return "footer__background--grey";
				case "light":
					return "footer__background--light";
				case "dark":
					return "footer__background--dark";
				default:
					return "";
			}
		});

		const applyOverlay = computed(() => {
			return !!props.footer.overlay || !!props.footer.gradient;
		});

		const overlayClasses = computed(() => {
			const overlay = props.footer.overlay;
			const gradient = props.footer.gradient;
			if (!overlay && !gradient) return false;

			const classes = {
				"footer__background--overlay": overlay,
				"footer__background--gradient": gradient,
			};

			if (overlay && props.footer.overlayOpacity) {
				classes[
					`footer__background--${props.footer.overlayOpacity}`
				] = true;
			}

			if (overlay && props.footer.overlayColor) {
				classes[
					`footer__background--${props.footer.overlayColor}`
				] = true;
			}

			if (gradient && props.footer.gradientColor) {
				classes[
					`footer__background--${props.footer.gradientColor}`
				] = true;
			}

			if (gradient && props.footer.gradientDirection) {
				classes[
					`footer__background--${props.footer.gradientDirection}`
				] = true;
			}

			return classes;
		});

		const overlay = computed(() => {
			return {
				enabled: applyOverlay.value,
				classes: overlayClasses.value,
			};
		});

		const setCroppedImage = (image) => {
			croppedImage.value = image;
		};

		return {
			applyOverlay,
			background,
			backgroundClass,
			backgroundImage,
			croppedImage,
			isImage,
			overlay,
			setCroppedImage,
		};
	},
};
